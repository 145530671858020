var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Custom Content" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeCustomContentWithIcon) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "app-timeline",
        [
          _c("app-timeline-item", { attrs: { icon: "DollarSignIcon" } }, [
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
              },
              [
                _c("h6", [_vm._v("12 Invoices have been paid")]),
                _c("small", { staticClass: "text-muted" }, [
                  _vm._v("12 min ago")
                ])
              ]
            ),
            _c("p", [_vm._v("Invoices have been paid to the company.")]),
            _c(
              "p",
              [
                _c("b-img", {
                  staticClass: "mr-1",
                  attrs: {
                    src: require("@/assets/images/icons/pdf.png"),
                    height: "auto",
                    width: "20"
                  }
                }),
                _c("span", { staticClass: "align-bottom" }, [
                  _vm._v("invoice.pdf")
                ])
              ],
              1
            )
          ]),
          _c(
            "app-timeline-item",
            { attrs: { variant: "secondary", icon: "UserIcon" } },
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
                },
                [
                  _c("h6", [_vm._v("Client Meeting")]),
                  _c("small", { staticClass: "text-muted" }, [
                    _vm._v("45 min ago")
                  ])
                ]
              ),
              _c("p", [_vm._v("Project meeting with john @10:15am.")]),
              _c(
                "b-media",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "aside",
                      fn: function() {
                        return [
                          _c("b-avatar", {
                            attrs: {
                              src: require("@/assets/images/avatars/12-small.png")
                            }
                          })
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c("h6", [_vm._v("John Doe (Client)")]),
                  _c("p", [_vm._v("CEO of Infibeam")])
                ]
              )
            ],
            1
          ),
          _c(
            "app-timeline-item",
            { attrs: { variant: "success", icon: "FileTextIcon" } },
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
                },
                [
                  _c("h6", [_vm._v("Financial Report")]),
                  _c("small", { staticClass: "text-muted" }, [
                    _vm._v("2 hours ago")
                  ])
                ]
              ),
              _c("p", [
                _vm._v("Click the button below to read financial reports")
              ]),
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "b-toggle",
                      rawName: "v-b-toggle.report-list-with-icon",
                      modifiers: { "report-list-with-icon": true }
                    },
                    {
                      name: "ripple",
                      rawName: "v-ripple.400",
                      value: "rgba(113, 102, 240, 0.15)",
                      expression: "'rgba(113, 102, 240, 0.15)'",
                      modifiers: { "400": true }
                    }
                  ],
                  attrs: { size: "sm", variant: "outline-primary" }
                },
                [_vm._v(" Show Report ")]
              ),
              _c(
                "b-collapse",
                { attrs: { id: "report-list-with-icon" } },
                [
                  _c(
                    "b-list-group",
                    { staticClass: "mt-1", attrs: { flush: "" } },
                    [
                      _c(
                        "b-list-group-item",
                        {
                          staticClass:
                            "d-flex justify-content-between align-items-center bg-transparent",
                          attrs: { href: "#" }
                        },
                        [
                          _c("span", [_vm._v("Last Year's Profit: $20,000")]),
                          _c("feather-icon", { attrs: { icon: "Share2Icon" } })
                        ],
                        1
                      ),
                      _c(
                        "b-list-group-item",
                        {
                          staticClass:
                            "d-flex justify-content-between align-items-center bg-transparent",
                          attrs: { href: "#" }
                        },
                        [
                          _c("span", [_vm._v("This Year's Profit: $25,000")]),
                          _c("feather-icon", { attrs: { icon: "Share2Icon" } })
                        ],
                        1
                      ),
                      _c(
                        "b-list-group-item",
                        {
                          staticClass:
                            "d-flex justify-content-between align-items-center bg-transparent",
                          attrs: { href: "#" }
                        },
                        [
                          _c("span", [_vm._v("Last Year's Commision: $5,000")]),
                          _c("feather-icon", { attrs: { icon: "Share2Icon" } })
                        ],
                        1
                      ),
                      _c(
                        "b-list-group-item",
                        {
                          staticClass:
                            "d-flex justify-content-between align-items-center bg-transparent",
                          attrs: { href: "#" }
                        },
                        [
                          _c("span", [_vm._v("This Year's Commision: $7,000")]),
                          _c("feather-icon", { attrs: { icon: "Share2Icon" } })
                        ],
                        1
                      ),
                      _c(
                        "b-list-group-item",
                        {
                          staticClass:
                            "d-flex justify-content-between align-items-center bg-transparent",
                          attrs: { href: "#" }
                        },
                        [
                          _c("span", [
                            _vm._v("This Year's Total Balance: $70,000")
                          ]),
                          _c("feather-icon", { attrs: { icon: "Share2Icon" } })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "app-timeline-item",
            { attrs: { variant: "warning", icon: "MapPinIcon" } },
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
                },
                [
                  _c("h6", [_vm._v("Interview Schedule")]),
                  _c("small", { staticClass: "text-muted" }, [
                    _vm._v("03:00 PM")
                  ])
                ]
              ),
              _c("p", [
                _vm._v("Have to interview Katy Turner for the developer job.")
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-sm-row flex-column justify-content-between align-items-start"
                },
                [
                  _c(
                    "b-media",
                    {
                      scopedSlots: _vm._u([
                        {
                          key: "aside",
                          fn: function() {
                            return [
                              _c("b-avatar", {
                                attrs: {
                                  src: require("@/assets/images/avatars/1-small.png")
                                }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _c("h6", [_vm._v("Katy Turner")]),
                      _c("span", { staticClass: "text-muted" }, [
                        _vm._v("Javascript Developer")
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    [
                      _c("feather-icon", {
                        staticClass: "mr-1",
                        attrs: { icon: "MessageSquareIcon" }
                      }),
                      _c("feather-icon", { attrs: { icon: "PhoneCallIcon" } })
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "app-timeline-item",
            { attrs: { variant: "danger", icon: "ShoppingBagIcon" } },
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
                },
                [
                  _c("h6", [_vm._v("Online Store")]),
                  _c("small", { staticClass: "text-muted" }, [
                    _vm._v("03:00 PM")
                  ])
                ]
              ),
              _c("p", [
                _vm._v(
                  "Develop an online store of electronic devices for the provided layout, as well as develop a mobile version of it. The must be compatible with any CMS."
                )
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-sm-row flex-column justify-content-between align-items-start"
                },
                [
                  _c("div", { staticClass: "mb-1 mb-sm-0" }, [
                    _c("span", { staticClass: "text-muted mb-50" }, [
                      _vm._v("Developers")
                    ]),
                    _c(
                      "div",
                      [
                        _c("b-avatar", {
                          staticClass: "mr-50",
                          attrs: {
                            text: "A",
                            size: "24",
                            variant: "light-primary"
                          }
                        }),
                        _c("b-avatar", {
                          staticClass: "mr-50",
                          attrs: {
                            text: "B",
                            size: "24",
                            variant: "light-success"
                          }
                        }),
                        _c("b-avatar", {
                          staticClass: "mr-50",
                          attrs: {
                            text: "C",
                            size: "24",
                            variant: "light-danger"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "mb-1 mb-sm-0" }, [
                    _c("span", { staticClass: "text-muted mb-50 d-block" }, [
                      _vm._v("Deadline")
                    ]),
                    _c("span", [_vm._v("20 Dec 2077")])
                  ]),
                  _c("div", [
                    _c("span", { staticClass: "text-muted mb-50 d-block" }, [
                      _vm._v("Budget")
                    ]),
                    _c("span", [_vm._v("$50,000")])
                  ])
                ]
              )
            ]
          ),
          _c(
            "app-timeline-item",
            { attrs: { variant: "info", icon: "GridIcon" } },
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex align-items-start flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-50"
                },
                [
                  _c("h6", [_vm._v("Designing UI")]),
                  _c(
                    "b-badge",
                    { attrs: { pill: "", variant: "light-primary" } },
                    [_vm._v(" Design ")]
                  )
                ],
                1
              ),
              _c("p", [
                _vm._v(
                  "Our main goal is to design a new mobile application for our client. The customer wants a clean & flat design."
                )
              ]),
              _c(
                "div",
                [
                  _c("span", { staticClass: "text-muted" }, [
                    _vm._v(" Participants ")
                  ]),
                  _c(
                    "b-avatar-group",
                    { staticClass: "mt-50", attrs: { size: "32px" } },
                    [
                      _c("b-avatar", {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true }
                          }
                        ],
                        staticClass: "pull-up",
                        attrs: {
                          title: "Jenny Looper",
                          src: require("@/assets/images/portrait/small/avatar-s-5.jpg")
                        }
                      }),
                      _c("b-avatar", {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true }
                          }
                        ],
                        staticClass: "pull-up",
                        attrs: {
                          title: "Jennifer Lopez",
                          src: require("@/assets/images/portrait/small/avatar-s-6.jpg")
                        }
                      }),
                      _c("b-avatar", {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true }
                          }
                        ],
                        staticClass: "pull-up",
                        attrs: {
                          title: "Arya Stark",
                          src: require("@/assets/images/portrait/small/avatar-s-7.jpg")
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }